// 标签项目列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      color="indigo"
      icon="mdi-clipboard-text"
      :title="`${tagName}（${total}）`"
      class="px-5 py-3 mt-12"
    >
      <v-breadcrumbs
        :items="breadcrumbs"
      />
      <search
        ref="search"
        :page="page"
        @search="searchData"
      />
      <v-simple-table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th>审核状态</th>
            <th>地市</th>
            <th>产业类别</th>
            <th>年度</th>
            <th class="text-right">
              标签
            </th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>
              <span
                class="indigo--text projectNameLink"
                @click="see(index)"
              >
                {{ project.name }}
              </span>
            </td>
            <td>{{ project.status | statusName }}</td>
            <td>{{ project.data.city }}</td>
            <td>{{ project.data.industry }}</td>
            <td>{{ project.data.year }}</td>
            <td>{{ project.data.tags }}</td>
            <td class="text-right">
              <v-btn
                v-if="project.status !== 5 || level === 'province'"
                class="px-2 ml-2"
                min-width="0"
                small
                color="blue mt-0"
                @click="edit(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                v-if="project.status === 0 || level === 'province'"
                class="px-2 ml-1"
                min-width="0"
                small
                color="red darken-1"
                @click="deleteProject(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="see(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="nowPage"
        :length="pageNum"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
    <div class="py-3" />
    <v-dialog
      v-model="deleteMobel"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          确定删除吗?
        </v-card-title>
        <v-card-text class="text-center">
          {{ ProjectName }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="orange"
            @click="deleteProject"
          >
            确定
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="deleteMobel = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    components: {
      search: () => import('../../components/base/Search'),
    },
    data: () => ({
      tagName: '标签',
      projectList: [], // 项目数据列表
      total: 0, // 项目统计数
      deleteMobel: false, // 删除确认框
      searchInitializeData: [],
      page: 0, // 搜索时用的页码
      nowPage: 1, // 页码组件用的页码
      pageNum: 0, // 页码长度
      ProjectId: '', // 项目ID
      ProjectName: '', // 项目名称
      searchProjectName: '',
      industryCategory: '全部', // 产业类别
      year: '全部', // 年度
      delete_project_success: false, // 成功删除项目
      searchParame: { // 搜索参数
        tags: '',
        page: 0,
        size: 15,
      },
      breadcrumbs: [ // 面包屑导航
        {
          text: '首页',
          disabled: false,
          href: '/',
        },
        {
          text: '标签',
          disabled: true,
        },
      ],
      isAdmin: true,
      level: '',
    }),
    watch: {
      '$route' (to, from) { // 监听路由是否变化
        if (to.params.formId !== from.params.formId) {
          this.searchParame.formId = to.params.formId
          this.getProjectList()
          delete this.searchParame.year
          delete this.searchParame.industry
          delete this.searchParame.name
        }
      },
    },
    created () {
      // 面包屑导航
      this.breadcrumbs[1].text = this.$route.params.tag
      // 获取标签名称
      this.tagName = this.$route.params.tag
      // 赋值搜索标签
      this.searchParame.tags = this.$route.params.tag
      // 判断你是不是admin
      this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
      // 本地存储级别
      this.level = localStorage.level
      // 获取项目表单
      // this.getProjectList()
    },
    methods: {
      // 获取项目列表
      getProjectList (searchParame) {
        console.log(searchParame)
        searchParame._from = 'org'
        searchParame.tags = this.tagName
        this.$axios.get('/reports/query', {
          // 搜索参数对象
          params: searchParame,
        })
          .then((res) => {
            const resData = res.data.data
            // 获取所有项目的统计数
            this.total = resData.totalElements
            // 获取项目列表
            this.projectList = resData.content
            // 获取项目数量
            this.pageNum = resData.totalPages
          })
          .catch((error) => {
            console.log(error)
          })
      },
      // 点击搜索按钮
      searchData (searchParame) {
        this.getProjectList(searchParame)
      },
      // 删除按钮弹出弹框
      deleteMobelBtn (item) {
        this.deleteMobel = true
        this.ProjectId = item.id
        this.ProjectName = item.name
      },
      // 编辑项目
      edit (index) {
        const routeData = this.$router.resolve({
          name: 'Report',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        })
        window.open(routeData.href, '_blank')
      },
      // 删除项目
      deleteProject (index) {
        this.$axios.delete('/reports/' + this.projectList[index].id)
          .then((response) => {
            this.deleteMobel = false
            this.delete_project_success = true
            this.getProjectList()
          })
          .catch((error) => {
            console.log(error)
          })
      },
      // 查看项目
      see (index) {
        const routeData = this.$router.resolve({
          name: 'ReportDetails',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        })
        window.open(routeData.href, '_blank')
      },
      // 换页按钮
      pageChange (value) {
        this.nowPage = value
        this.page = value - 1
      },
    },
  }
</script>

<style scoped>
.projectNameLink {
  cursor: pointer;
}
</style>
